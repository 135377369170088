<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <!-- ============================ Page Title Start================================== -->
      <div class="page-title theme-bg">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h2 class="ipt-title">Agencies</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- ============================ Page Title End ================================== -->

      <!-- ============================ Search Form End ================================== -->
      <section class="bg-light p-0">
        <div class="container">
          <!-- row Start -->
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12">
              <div
                class="full-search-2 eclip-search italian-search hero-search-radius shadow-hard overlio-40"
              >
                <div class="hero-search-content">
                  <form @submit.prevent="fetch">
                    <div class="row">
                      <div class="col-lg-10 col-md-9 col-sm-12 p-0">
                        <div class="form-group">
                          <div class="input-with-icon">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search for a agency, agent or location"
                              v-model="search_term"
                            />
                            <img src="/assets/img/move.svg" width="20" alt="" />
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2 col-md-3 col-sm-12">
                        <div class="form-group">
                          <button class="btn search-btn black"
                            >Search</button
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
      </section>
      <!-- ============================ Search Form End ================================== -->

      <!-- ============================ Agent List Start ================================== -->
      <section class="bg-light">
        <div class="container">
          <loading v-if="loading" />
          <div class="row">
            <!-- Single Agent -->
            <div class="col-lg-4 col-md-6 col-sm-12" v-for="(agency, i) in agencies" :key="`agency-${i}`">
              <div class="agents-grid">
                <div class="agents-grid-wrap">
                  <div class="text-center">
                    <router-link :to="{ name: 'page', params: { slug: agency.slug }}">
                      <img
                        :src="agency.logo"
                        class="img-fluid py-3"
                        :alt="agency.name"
                        style="max-height: 200px;"
                      />
                    </router-link>
                  </div>

                  <div class="fr-grid-deatil">
                    <div class="fr-grid-deatil-flex">
                      <h5 class="fr-can-name">
                        <router-link :to="{ name: 'page', params: { slug: agency.slug }}">{{ agency.name }}</router-link>
                      </h5>
                      <span class="agent-property">{{ agency.properties }} Properties</span>
                    </div>
                  </div>
                </div>

                <div class="fr-grid-info">
                  <ul>
                    <li>
                      <div class="fr-can-rating">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span class="reviews_text">(0 Reviews)</span>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="fr-grid-footer">
                  <div class="fr-grid-footer-flex">
                    <span class="fr-position"
                      ><i class="lni lni-map-marker"></i>{{ agency.address }}</span
                    >
                  </div>
                  <div class="fr-grid-footer-flex-right">
                    <router-link :to="{ name: 'page', params: { slug: agency.slug }}" class="prt-view" tabindex="0"
                      >View</router-link
                    >
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
              <pagination
                v-model="pagination.page"
                :records="pagination.total"
                :perPage="pagination.per_page"
                @paginate="fetch"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Agent List End ================================== -->

      <!-- ============================ Call To Action ================================== -->
      <section class="theme-bg call-to-act-wrap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="call-to-act">
                <div class="call-to-act-head">
                  <h3>Post your property on RealHub</h3>
                  <span
                    >We'll help you get to potential clients much faster.</span
                  >
                </div>
                <router-link
                  :to="{ name: 'submit-property' }"
                  class="btn btn-call-to-act"
                  >Submit Property</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Call To Action End ================================== -->

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    document.title = 'Discover Top-Rated Properties with Trusted Agencies: Realhub Kenya Listings'

    this.fetch()
  },

  data() {
    return {
      search_term: '',
      agencies: [],
      loading: true,
      pagination: { page: 1, per_page: 10, total: 0 }
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/agencies?page=${this.pagination.page}&search=${this.search_term}`).then(response => {
        this.agencies = response.data.agencies
        this.pagination = response.data.pagination
        this.loading = false
      })
    }
  },

  watch: {
    url() {
      this.fetch()
    }
  }
};
</script>
